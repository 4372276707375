<template>
  <div class="home">
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue'
export default {
  components: {
    Dashboard
  },
}
</script>