<template>
  <div class="dashboard-page">
    <div class="dashboard-button-group">
      <el-button type="primary" size="small" @click="changeDashboard(0)" :plain="0 == linkcode ? false : true">Campaign</el-button>
      <el-button type="primary" size="small" @click="changeDashboard(1)" :plain="1 == linkcode ? false : true">KPI</el-button>
      <el-button type="primary" size="small" @click="changeDashboard(2)" :plain="2 == linkcode ? false : true">Credibility</el-button>
      <el-button type="primary" size="small" @click="changeDashboard(3)" :plain="3 == linkcode ? false : true">Benchmark</el-button>
    </div>
    <el-button type="login" size="small" class="logout-btn" @click="onLogout()">登出</el-button>
    <div v-html="iframehtml" class="dashboard-iframe"></div>
  </div>
</template>

<script>
export default {

  data(){
    return{
      iframehtml: '',
      linkcode: 0,
      linkcampaign: 'https://app.powerbi.cn/view?r=eyJrIjoiNWNmYTlmNjgtNzcwYi00YWM2LWJmMTUtYTVmZGE5NmI3YjBhIiwidCI6ImFhMGJkZWFjLThhMDQtNDFmYS1hNDk1LThmNzE3Njk4ZWI4NCJ9&pageName=ReportSection',
      linkkpi: 'https://app.powerbi.cn/view?r=eyJrIjoiY2JhZjFmOTMtM2Y3Yy00NDY3LWFiMDktNzJiM2UwYmY2YmIxIiwidCI6ImFhMGJkZWFjLThhMDQtNDFmYS1hNDk1LThmNzE3Njk4ZWI4NCJ9',
      linkabnormal: 'https://app.powerbi.cn/view?r=eyJrIjoiM2RjZDQ5NTQtYzY0Mi00Zjc1LWJkZTAtMmMwNjA2ODhiZWNhIiwidCI6ImFhMGJkZWFjLThhMDQtNDFmYS1hNDk1LThmNzE3Njk4ZWI4NCJ9',
      linkbenchmark: 'https://app.powerbi.cn/view?r=eyJrIjoiOWU4MzczYjAtYTQ0ZS00ZjkwLWEzNmItMDM2Y2M4Mjg3OGU5IiwidCI6ImFhMGJkZWFjLThhMDQtNDFmYS1hNDk1LThmNzE3Njk4ZWI4NCJ9'
    }
  },

  mounted(){
    this.loadIframe(0)
    this.validateToken()
  },

  methods: {
    /**
     * 切换 Dashboard
     */
    changeDashboard: function(link){
      this.loadIframe(link)
      this.linkcode = link/1
    },

    /**
     * 加载 Dashboard 框架页面
     */
    loadIframe: function(link){
      let pbiurl = ''
      if(link == 0) pbiurl = this.linkcampaign
      if(link == 1) pbiurl = this.linkkpi
      if(link == 2) pbiurl = this.linkabnormal
      if(link == 3) pbiurl = this.linkbenchmark
      let winHeight = document.documentElement.clientHeight + 35
      let pbiUrl = pbiurl
      this.iframehtml = '<iframe width="100%" height="' + winHeight + '" src="' + pbiUrl + '" frameborder="0" allowFullScreen="true"></iframe>'
    },

    /**
     * 登出
     */
    onLogout: function(){
      let that = this
      that.$axios.post(that.API_URL + '/Loginout', {
        headers: {'Authorization': 'Bearer '+JSON.parse(localStorage.getItem("token")).val+''}
      })
      .then(function () {
        localStorage.removeItem("token")
        that.$router.push({ path: "/Login" })
      })
      .catch(function (error) {
        console.log(error)
      })
    },

    /**
     * 校验 Token 是否过期
     */
    validateToken: function(){
      let that = this
      var config = {
        method: 'post',
        url: that.API_URL + '/validateToken',
        headers: { 
          'Authorization': JSON.parse(localStorage.getItem("token")).val
        }
      };
      that.$axios(config)
      .then(function (res) {
        if(res.data.msg == '校验通过'){
          return
        }
        if(res.data.msg == 'token过期'){
          localStorage.removeItem("token")
          that.$router.push({ path: "/Login" })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
    },
  }
}
</script>